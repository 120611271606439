import Parseable from '@/misc/Parseable';
import StatisticsSeries from "@/models/StatisticsSeries";
import Transaction from "@/models/Transaction.model";
import Wallet from "@/models/Wallet.model";
import MicroservicePayment from "@/models/MicroservicePayment.model";
import {TransactionStatisticType} from "@/enum/TransactionStatisticType.enum";

export default class TransactionStatistic extends Parseable {
    public type!: TransactionStatisticType;
    public date!: string;
    public values!: any[];

    public static parseFromObject(object: any): TransactionStatistic {
        const ts = new TransactionStatistic();
        Object.assign(ts, object);
        return ts;
    }

    public parseToObject(): Partial<TransactionStatistic> {
        return { ...this };
    }
}
