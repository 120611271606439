import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";

/**
 * repository that provides all the api requests that deal with ContainerType
 */
export default class ContainerStatisticsRepository {
    private static baseUrl: string = 'api/statistics';

    /**
     * gets the statistics for one container of a company
     * @param companyId
     * @param containerTypeId
     */
    public static getStatisticsByCompany(companyId: string, containerTypeId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}?companyId=${companyId}&containerTypeId=${containerTypeId}`);
    }

    /**
     * gets the statistics for a company, this includes multiple container statistics
     * @param companyId
     */
    public static getAllStatisticsOfCompany(companyId: string) {
        return ENTITY_CLIENT.get(`${this.baseUrl}/${companyId}`);
    }
}
