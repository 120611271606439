import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";

/**
 * repository that provides all the api requests that deal with ContainerType
 */
export default class CompanyIncomeRepository {
    private static baseUrl: string = 'api/company/income';

    /**
     * gets the income of a company
     * @param companyId
     */
    public static getIncome(companyId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/${companyId}`);
    }
}
