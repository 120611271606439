import Parseable from '@/misc/Parseable';
import StatisticsSeries from "@/models/StatisticsSeries";

export default class CompanyStatistics extends Parseable {
    public neededStock!: number;
    public returnRatio!: number;

    public statistics!: StatisticsSeries[];
    public rentalTrend!: number;
    public containerRange!: number;
    public containerRangeTrend!: number;
    public remainingStock!: number;
    public containersInCirculation!: number;

    public static parseFromObject(object: any): CompanyStatistics {
        const cs = new CompanyStatistics();
        Object.assign(cs, object);
        return cs;
    }

    public parseToObject(): Partial<CompanyStatistics> {
        return { ...this };
    }
}
