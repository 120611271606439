import Parseable from '@/misc/Parseable';
import Company from "@/models/Company";
import {ContainerTypeStatus} from "@/enum/ContainerTypeStatus";

export default class ContainerType extends Parseable {
    public id?: string;
    public isDefaultType?: boolean;
    public name?: string;
    public hasDynamicStock?: boolean;
    public lastPenaltyRate?: number;
    public company?: Company;
    public stock!: number;

    public status!: ContainerTypeStatus;

    public description?: string;
    public internalInstruction?: string;

    public static parseFromObject(object: any): ContainerType {
        const containerType = new ContainerType();
        Object.assign(containerType, object);
        return containerType;
    }

    public parseToObject(): Partial<ContainerType> {
        return { ...this };
    }
}
