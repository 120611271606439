
import { namespace } from 'vuex-class';
import { Component, Watch, Vue } from 'vue-property-decorator';
import {ROUTE_COMPANY} from '@/router/routes';
import { NavigationMenuItems, NavigationMenuCategory } from '@/misc/NavigationMenuItems';
import {APPLICATION_STORE_NAME, ApplicationStoreGetters} from '@/store/application.store';
import Company from "@/models/Company";
import ContainerStatisticsRepository from "@/api/repositories/ContainerStatisticsRepository";
import {CONTAINER_STORE_NAME, ContainerStoreActions} from "@/store/container.store";
import Container from "@/models/Container.model";
import StatisticsSeries from "@/models/StatisticsSeries";
import ContainerStatistics from "@/models/ContainerStatistics";
import CompanyStatistics from "@/models/CompanyStatistics.model";
import StatisticsRepository from "@/api/repositories/StatisticsRepository";
import CompanyIncomeRepository from "@/api/repositories/CompanyIncomeRepository";
import TransactionStatistic from "@/models/TransactionStatistics";
import {AdvertisementSize} from "@/enum/AdvertisementSize.enum";

const ApplicationStore = namespace(APPLICATION_STORE_NAME);
const ContainerStore = namespace(CONTAINER_STORE_NAME);

@Component({
  components: {
    CompanyStatisticsComponent: () => import(
        /* webpackChunkName: "CompanyStatisticsComponent" */
        '@/components/Company/CompanyStatistics.component.vue'
        ),
    StatisticsListComponent: () => import(
        /* webpackChunkName: "StatisticsListComponent" */
        '@/components/Statistics/StatisticsList.component.vue'
        ),
    AdvertisementComponent: () => import(
        /* webpackChunkName: "AdvertisementComponent" */
        '@/components/Advertisement.component.vue'
        ),
  }
})
export default class CompanyOverviewView extends Vue {
  @ApplicationStore.Getter(ApplicationStoreGetters.CURRENT_COMPANY)
  private currentCompany?: Company;

  @ContainerStore.Action(ContainerStoreActions.GET_ALL)
  private getAllContainersActions!: () => Promise<Container[]>;

  private standardLeaderboardAdvertisement = AdvertisementSize.LARGE_LEADERBOARD;
  private billboardAdvertisement = AdvertisementSize.BILLBOARD;

  /**
   * title of the view
   */
  private title: string = '';

  /**
   * flag that indicates if the current company edit modal should be shown
   */
  private showCompanyEditDialog: boolean = false;

  /**
   * company statistics model
   * @private
   */
  private containerStatistic: CompanyStatistics = new CompanyStatistics();

  /**
   * income model
   * @private
   */
  private income: number | null = null;

  /**
   * holds the information about all the statistics loaded from api
   * @private
   */
  private transactionStatistic: TransactionStatistic[] | null = null;

  /**
   * loading flag to indicate if the COMPANY STATISTICS are loading
   */
  private isLoadingContainerStatistics = false;

  /**
   * loading flag to indicate if the TRANSACTION STATISTICS are loading
   * @private
   */
  private isLoadingStatistics = false;

  /**
   * loading flag to indicate if the WALLET BALANCE is loading
   * @private
   */
  private isLoadingWallet = false;

  /**
   * Used to only show store properties if current page is store overview page.
   */
  get showAdditionalProperties(): boolean {
    return this.$route.name === ROUTE_COMPANY;
  }

  @Watch('$route', { immediate: true })
  private onRouteChanged() {
    this.title = this.getTitle();
  }

  /**
   * Returns title of currently active page.
   */
  private getTitle(): string {
    const storeCategory = NavigationMenuItems.find(category => category.name == NavigationMenuCategory.COMPANY_PANEL)!;
    const activeItem = storeCategory.items.find(item => item.route === this.$route.name);
    return activeItem ? `${this.$t(activeItem?.name)}` : '';
  }

  /**
   * fetches all data simultaneously
   * @private
   */
  private async created() {
    await Promise.all([
      this.fetchCompanyStatistics(),
      this.fetchStatistics(),
      this.fetchIncome()
    ]);
  }

  /**
   * fetches the company statistics (about containers etc.)
   * @private
   */
  private async fetchCompanyStatistics() {
    try {
      this.isLoadingContainerStatistics = true;
      const { data } = await ContainerStatisticsRepository.getAllStatisticsOfCompany(this.$route.params.id);

      const series = data.statistics.map((s: ContainerStatistics) => {
        return StatisticsSeries.parseFromObject({statistics: s, name: s.type.name});
      });

      this.containerStatistic = CompanyStatistics.parseFromObject({...data, statistics: series});
    } catch(e) {
      this.containerStatistic = CompanyStatistics.parseFromObject({statistics: []});
    } finally {
      this.isLoadingContainerStatistics = false;
    }
  }

  /**
   * fetches the income / balance of the company
   * @private
   */
  private async fetchIncome() {
    try {
      this.isLoadingWallet = true;
      const {data} = await CompanyIncomeRepository.getIncome(this.$route.params.id);
      this.income = data.balance;
    } finally {
      this.isLoadingWallet = false;
    }
  }

  /**
   * fetches the statistics based on the company id and the date inside the picker
   * @private
   */
  private async fetchStatistics(month?: string) {
    try {
      this.isLoadingStatistics = true;

      // gets the companyId from the url, gets all statistics of that company and maps them in transaction object
      const id = this.$route.params.id;
      const { data } = await StatisticsRepository.getStatistics(id, this.pickerIsoString(month));
      if(data && data.length > 0) {
        this.transactionStatistic = data.map((t: TransactionStatistic) => TransactionStatistic.parseFromObject(t));
      } else {
        this.transactionStatistic = [];
      }
    } catch(e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.CANNOT_LOAD_STATISTICS');
    } finally {
      this.isLoadingStatistics = false;
    }
  }

  /**
   * iso string date for passing it in the fetch api request
   * @private
   */
  private pickerIsoString(value?: string) {
    if(value == '' || !value) return null;
    return new Date(value!).toISOString();
  }
}
