import Parseable from '@/misc/Parseable';

export default class StatisticsData extends Parseable {
    public dates!: string[];
    public values!: number[];

    public static parseFromObject(object: any): StatisticsData {
        const containerStatistics = new StatisticsData();
        Object.assign(containerStatistics, object);
        return containerStatistics;
    }

    public parseToObject(): Partial<StatisticsData> {
        return { ...this };
    }
}
