import Parseable from '@/misc/Parseable';
import ContainerStatisticsData from "@/models/StatisticsData";
import StatisticsData from "@/models/StatisticsData";
import ContainerType from "@/models/ContainerType.model";

export default class ContainerStatistics extends Parseable {
    public type!: ContainerType;
    public remainingStock!: number;
    public rentalTrend!: number;
    public containerRange!: number;
    public containerRangeTrend!: number;
    public containersInCirculation!: number;
    public containersInCirculationData!: StatisticsData;

    public static parseFromObject(object: any): ContainerStatistics {
        const cs = new ContainerStatistics();
        Object.assign(cs, object);
        cs.containersInCirculationData = ContainerStatisticsData.parseFromObject(cs.containersInCirculationData);
        cs.type = ContainerType.parseFromObject(cs.type);
        return cs;
    }

    public parseToObject(): Partial<ContainerStatistics> {
        return { ...this };
    }
}
