import { AxiosResponse } from 'axios';
import { AUTH_CLIENT } from '../misc/AuthClient';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";

/**
 * repository that deals with all the api requests that have to do with statistics
 */
export default class StatisticsRepository {
    private static baseUrl: string = 'api/statistics';

    /**
     * gets transaction statistics of a certain company
     */
    public static getStatistics(companyId: string, date: string | null): Promise<AxiosResponse> {
        const query = date ? `?date=${date}`: "";
        return ENTITY_CLIENT.get(`${this.baseUrl}/${companyId}/transactions${query}`);
    }
}
