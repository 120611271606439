import Parseable from '@/misc/Parseable';
import ContainerStatistics from "@/models/ContainerStatistics";

export default class StatisticsSeries extends Parseable {
    public name!: string;
    public statistics!: ContainerStatistics;

    public static parseFromObject(object: any): StatisticsSeries {
        const series = new StatisticsSeries();
        Object.assign(series, object);
        series.statistics = ContainerStatistics.parseFromObject(series.statistics);
        return series;
    }

    public parseToObject(): Partial<StatisticsSeries> {
        return { ...this };
    }
}
