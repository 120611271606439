
import { namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import {APPLICATION_STORE_NAME} from '@/store/application.store';

const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component({
  components: {
    CompanyStatisticsComponent: () => import(
      /* webpackChunkName: "CompanyStatisticsComponent" */
      '@/components/Company/CompanyStatistics.component.vue'
    ),
    StatisticsListComponent: () => import(
      /* webpackChunkName: "StatisticsListComponent" */
      '@/components/Statistics/StatisticsList.component.vue'
    ),
    AdvertisementComponent: () => import(
      /* webpackChunkName: "AdvertisementComponent" */
      '@/components/Advertisement.component.vue'
    ),
  }
})
export default class CompanyView extends Vue {}
