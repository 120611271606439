import {AccountType} from '@/enum/AccountType.enum';
import {
  ROUTE_ADVERTISEMENTS, ROUTE_APP_DOWNLOAD, ROUTE_BONIFICATION,
  ROUTE_COMPANIES,
  ROUTE_COMPANY, ROUTE_COMPANY_CONTAINER_TYPE_REQUESTS, ROUTE_CONTAINER_TYPE_REQUESTS,
  ROUTE_CONTAINERS,
  ROUTE_EMPLOYEES,
  ROUTE_HISTORY,
  ROUTE_INFO_FEED, ROUTE_MICRO_SERVICES,
  ROUTE_PENALTY_PAYMENTS
} from '@/router/routes';
import {NavigationMenuCategoryInterface} from "@/interfaces/NavigationMenuCategory.interface";
import store from '@/store';
import {AUTH_STORE_NAME, AuthStoreGetters} from "@/store/auth.store";
import {APPLICATION_STORE_NAME, ApplicationStoreGetters} from "@/store/application.store";

export enum NavigationMenuCategory {
  ADMIN_PANEL,
  COMPANY_PANEL
}

export const NavigationMenuItems: NavigationMenuCategoryInterface[] = [
  {
    name: NavigationMenuCategory.ADMIN_PANEL,
    isValid: () => store.getters[`${AUTH_STORE_NAME}/${AuthStoreGetters.CURRENT_USER}`].account.role === AccountType.PB,
    items: [
      // TODO: reintroduce later, when individual containers are back in the application
      // {
      //   name: 'ROUTES.CONTAINER_TYPE_REQUESTS',
      //   route: ROUTE_CONTAINER_TYPE_REQUESTS,
      //   isActive: false,
      //   selectedIcon: 'requested_containers_selected.svg',
      //   unselectedIcon: 'requested_containers_unselected.svg',
      //   authorizedRoles: [AccountType.PB]
      // },
      {
        name: 'ROUTES.COMPANIES',
        route: ROUTE_COMPANIES,
        isActive: false,
        selectedIcon: 'employees_selected.svg',
        unselectedIcon: 'employees_unselected.svg',
        authorizedRoles: [AccountType.PB]
      },
      {
        name: 'ROUTES.ADVERTISEMENT',
        route: ROUTE_ADVERTISEMENTS,
        isActive: false,
        selectedIcon: 'ads_selected.svg',
        unselectedIcon: 'ads_unselected.svg',
        authorizedRoles: [AccountType.PB]
      },
      {
        name: 'ROUTES.BONIFICATION',
        route: ROUTE_BONIFICATION,
        isActive: false,
        selectedIcon: 'employees_selected.svg',
        unselectedIcon: 'employees_unselected.svg',
        authorizedRoles: [AccountType.PB]
      },
    ],
    authorizedRoles: [AccountType.PB]
  },
  {
    name: NavigationMenuCategory.COMPANY_PANEL,
    isValid: () => store.getters[`${APPLICATION_STORE_NAME}/${ApplicationStoreGetters.CURRENT_COMPANY}`] != undefined,
    items: [
      {
        name: 'ROUTES.COMPANY',
        route: ROUTE_COMPANY,
        isActive: false,
        selectedIcon: 'home_selected.svg',
        unselectedIcon: 'home_unselected.svg',
        authorizedRoles: [AccountType.GN, AccountType.PB]
      },
      {
        name: 'ROUTES.MICRO_SERVICES',
        route: ROUTE_MICRO_SERVICES,
        isActive: false,
        selectedIcon: 'history_selected.svg',
        unselectedIcon: 'history_unselected.svg',
        authorizedRoles: [AccountType.GN, AccountType.PB]
      },
      {
        name: 'ROUTES.CONTAINERS',
        route: ROUTE_CONTAINERS,
        isActive: false,
        selectedIcon: 'bowl_big_selected.svg',
        unselectedIcon: 'bowl_big_unselected.svg',
        authorizedRoles: [AccountType.GN, AccountType.PB]
      },
      // TODO: reintroduce later, when individual containers are back in the application
      // {
      //   name: 'ROUTES.COMPANY_CONTAINER_TYPE_REQUESTS',
      //   route: ROUTE_COMPANY_CONTAINER_TYPE_REQUESTS,
      //   isActive: false,
      //   selectedIcon: 'requested_containers_selected.svg',
      //   unselectedIcon: 'requested_containers_unselected.svg',
      //   authorizedRoles: [AccountType.PB]
      // },
      {
        name: 'ROUTES.PENALTY_PAYMENTS',
        route: ROUTE_PENALTY_PAYMENTS,
        isActive: false,
        selectedIcon: 'wallet_selected.svg',
        unselectedIcon: 'wallet_unselected.svg',
        authorizedRoles: [AccountType.GN, AccountType.PB]
      },
      {
        name: 'ROUTES.HISTORY',
        route: ROUTE_HISTORY,
        isActive: false,
        selectedIcon: 'history_selected.svg',
        unselectedIcon: 'history_unselected.svg',
        authorizedRoles: [AccountType.GN, AccountType.PB]
      },
      {
        name: 'ROUTES.INFO_FEED',
        route: ROUTE_INFO_FEED,
        isActive: false,
        selectedIcon: 'feed_selected.svg',
        unselectedIcon: 'feed_unselected.svg',
        authorizedRoles: [AccountType.GN, AccountType.PB]
      },
      {
        name: 'ROUTES.EMPLOYEES',
        route: ROUTE_EMPLOYEES,
        isActive: false,
        selectedIcon: 'employees_selected.svg',
        unselectedIcon: 'employees_unselected.svg',
        authorizedRoles: [AccountType.GN, AccountType.PB]
      },
      {
        name: 'ROUTES.APP_DOWNLOAD',
        route: ROUTE_APP_DOWNLOAD,
        isActive: false,
        selectedIcon: 'app_download_selected.svg',
        unselectedIcon: 'app_download_unselected.svg',
        authorizedRoles: [AccountType.GN, AccountType.PB]
      },
    ],
    authorizedRoles: [AccountType.PB, AccountType.GN]
  }
];
